.flex-rows {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: 576px) {
      width: 100%;
      flex-direction: column;
    }
    .icon-text {
      width: 95%;
      display: flex;
      justify-content: space-around;
      .icon-container {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
  
        .icon-download {
          background: url("../../../../public/Icons/BidMyRide-download.png");
          height: 20px;
          width: 20px;
          display: block;
          background-repeat: no-repeat;
          background-size: contain;
        }
        .icon-attach {
          background: url("../../../../public/Icons/BidMyRide-attach.png");
          height: 20px;
          width: 20px;
          display: block;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      p {
        box-sizing: border-box;
      }
    }
  }
  