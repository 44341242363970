.paragraph {
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: normal;
    color: #fff;
    font-family: "Graphik-Light";
    font-weight: normal;

}