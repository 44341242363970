.mb-space-1{
    margin-bottom: 2rem;
}
.mb-space-2{
    margin-bottom: 4rem;
}
.mb-space-3{
    margin-bottom: 5rem;
}
.mb-space-4{
    margin-bottom: 6rem;
}