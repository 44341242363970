.index-page-banner-container {
  .carousel-slideshow-container {
    position: relative;
    margin: auto;

    .carousel-slide-content {
      overflow: hidden;

      img {
        min-height: 700px;
        max-height: 700px;
        object-fit: cover;

        @media screen and (max-width: 567px) {
          min-height: 400px;
          max-height: 400px;
        }
      }
    }

    .logo-content-box {
      position: absolute;
      width: 100%;
      top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Rubik-Regular";

      .slider-text-container {
        position: absolute;
        top: 0;
        margin: 0 auto;
        text-align: center;

        .gcLogo {
          height: 56px;
          margin: auto;
        }
      }
    }
  }
}
