.bid-my-ride-form {
  -webkit-backdrop-filter: blur(10.3px);
  backdrop-filter: blur(10.3px);
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 6.9px;
  padding-top: 6px;

  .input-field {
    padding: 5px 0 5px 15px;
    border-radius: 6.9px;
    border: 2.3px solid hsla(0, 0%, 100%, 0.4);
    margin: 6px;
    height: 56px;

    .input-label {
      display: block;
      font-size: 15px;
      color: #fff;
      font-family: "SFCompactDisplayMedium";
      margin: 0;
      text-transform: uppercase;

      &.tooltip-icon {
        position: relative;
      }
    }

    .tooltiptext {
      visibility: hidden;
      width: auto;
      color: #fff;
      text-align: center;
      margin-left: 2px;
      position: absolute;
      z-index: 1;
      text-transform: none;
    }

    .tooltip-icon:hover .tooltiptext {
      visibility: visible;
    }

    input {
      outline: none;
      border: none;
      display: block;
      line-height: 1.2em;
      font-size: 16px;
      width: 100%;
      color: #fff;
      background-color: transparent;
      font-family: "SFCompactDisplayMedium";
      padding: 0;
    }
  }

  .input-field-checkbox {
    margin: 6px;

    .agree_checkbox_label {
      color: #fff;
    }

    .agree_checkbox_text {
      margin-right: 5px;
    }
  }

  .textareaType {
    height: 80px;
  }

  .error-field {
    text-align: left;
    margin-bottom: 5px;
    line-height: 18px;

    p {
      margin-left: 10px;
      font-family: "SFCompactDisplayMedium";
      color: #ff6181 !important;
      letter-spacing: 1px;
      text-shadow: none !important;
      font-size: 17px;
    }
  }

  textarea {
    outline: none;
    border: none;
    display: block;
    line-height: 1.2em;
    font-size: 17px;
    width: 100%;
    color: #fff;
    background-color: transparent;
    font-family: "SFCompactDisplayMedium";
  }

  select {
    background-color: transparent;
    outline: none;
    border: none;
    line-height: 1.2em;
    font-size: 17px;
    width: 100%;
    color: #fff;
    font-family: "SFCompactDisplayMedium";

    option {
      color: #000;
    }
  }

  .submit-container {
    text-align: center;
  }

  input[type="submit"] {
    height: 35px;
    width: 50%;
    color: #fff;
    line-height: 1.72;
    letter-spacing: normal;
    margin: 10px auto;
    font-size: 17px;
    font-weight: 600;
    font-family: "SFCompactDisplayBold";
    cursor: pointer;
    border-radius: 17.9px;
    background-image: linear-gradient(136deg, #0d8abf 47%, #51b4e1 62%);
  }

  .input-radio-field {
    font-size: 18px;
    font-family: "SFCompactDisplayMedium";
    color: white;
    height: 65px;

    label {
      display: inline;
    }

    input {
      display: inline;
      width: auto;
      margin-right: 5px;
    }
  }

  .google-recaptcha {
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
