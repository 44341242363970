.default-text {
  font-family: "Graphik-Regular";
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #373737;
  margin-bottom: 1rem;
  @media only screen and (max-width: 576px) {
    letter-spacing: 0.1px;
  }
}
