.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}



@font-face {
  font-family: "Graphik-Regular";
  src: url("fonts/Graphik-Font-Family/GraphikRegular.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Graphik-Black"; 
  src: url("fonts/Graphik-Font-Family/GraphikBlack.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Graphik-ExtraLight"; 
  src: url("fonts/Graphik-Font-Family/GraphikExtralight.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Graphik-Light"; 
  src: url("fonts/Graphik-Font-Family/GraphikLight.otf");
  font-weight: 300;
}
@font-face {
  font-family: "Graphik-BlackItalic"; 
  src: url("fonts/Graphik-Font-Family/GraphikBlackItalic.otf");
  font-weight: normal;
}
@font-face {
  font-family: "Graphik-Medium"; 
  src: url("fonts/Graphik-Font-Family/GraphikMedium.otf");
  font-weight: 500;
}

body {
  font-family: "Graphik-Regular";
}