.operator-page {
    width: 100%;
    display: flex;
    justify-content: center;

    .operator-form-container {
        width: 30%;
    }
  
    @media only screen and (max-width: 576px) {
      display: block;
    }
}