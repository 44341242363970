@import "../../../components/variables.scss";
@import "../../../components/mixins.scss";
.bid-operator-signup {
  width: 80%;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
  .bid-signup-fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: stretch;
    margin-bottom: 0.7rem;
    @media only screen and (max-width: 576px) {
      flex-direction: column;
      width: 100%;
      margin-bottom: 1rem;
    }
    input {
      background: transparent;
      background-color: #f8f5f5;
      border: 0.1px solid rgba(78, 74, 74, 0.01);
      border-radius: 6px;
      box-sizing: border-box;
      width: 98%;
      color: $black;
    }

    .input-box {
      width: 98%;
      p {
        padding: 0.2rem;
        margin: 0px;
      }
      @media only screen and (max-width: 576px) {
        width: 100%;
        margin-bottom: 0.5rem;
      }
      & ::placeholder {
        color: #666666;
        font-size: 11px;
        font-family: $mediumFont;
        font-weight: lighter;
      }
    }
  }
  .server-error > p {
    color: black;
    text-align: center;
    margin-top: 0.3rem;
  }
}
