@import "../../../components/variables.scss";
@mixin button(
  $width: 100%,
  $height: 35px,
  $border: 1px solid $blueColor,
  $bg-color: $blueColor,
  $color: $white,
  $border-radius: 5px,
  $padding: 0px,
  $align: center,
  $fontSize: 12px,
  $fontWeight: normal,
  $family: $mediumFont
) {
  width: $width;
  background-color: $bg-color;
  color: $color;
  border-radius: $border-radius;
  height: $height;
  border: $border;
  padding: $padding;
  text-align: $align;
  font-family: $family;
  font-size: $fontSize;
  font-weight: $fontWeight;
}
.bidBtn {
  @include button;
  &:hover {
    background-color: $blueColor;
    color: $white;
  }
  &:focus {
    background-color: $blueColor;
    color: $white;
  }
}
.blue {
  @extend .bidBtn;
  background-color: $blueColor;
}
.white {
  @extend .bidBtn;
  background-color: $white;
  width: 274px;
  color: $blueColor;
  @media only screen and (max-width: 576px) {
    width: 100%;
    margin-top: 0.6rem;
  }
  &:hover {
    background-color: $white;
    color: $blueColor;
  }
  &:active {
    background-color: $white;
    color: $blueColor;
  }
}
