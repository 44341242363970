@import "../../components/variables.scss";
@import "../../components/mixins.scss";

.bid-main-container {
  width: 100%;
  min-height: 100vh;
  .bid-header-container {
    margin: auto auto;
    @include row-space-between;
    .bid-image-desc {
      display: flex;
      width: 70%;
      flex-flow: column wrap;
      .custom-para {
        width: 75%;
        @media only screen and (max-width: 576px) {
          width: 100%;
        }
      }
      .mega-title {
        width: 80%;
        @media only screen and (max-width: 576px) {
          width: 100%;
          font-size: 2rem;
          letter-spacing: 0.1px;
        }
      }
      @media only screen and (max-width: 576px) {
        width: 100%;
      }
    }
    .bid-image-container {
      width: 50%;
      display: flex;
      justify-content: right;
      @media only screen and (max-width: 576px) {
        display: none;
      }
    }
  }
  .operator-form-desc {
    width: 30%;
    @media only screen and (max-width: 576px) {
      width: 100%;
    }
  }
  .operator-form {
    flex-grow: 1;
  }
  .support-text {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .container-xl {
    color: $white;
    padding: 0px;
    @media only screen and (max-width: 576px) {
      display: none;
    }
    .text-medium {
      color: $white;
    }
  }
  .container-md {
    display: none;
    @media only screen and (max-width: 576px) {
      display: block;
    }
    .text-medium {
      color: $white;
    }
  }
  .bid-container {
    color: $white;
  }
  .bid-operator-review {
    p {
      font-size: 21px;
      font-weight: 500;
      color: $primaryColor;
      margin: 1em;
    }

    .bid-review-container {
      width: 100%;
      opacity: 0.02;
      border-radius: 8px;
      background-color: $black;
      height: 200px;
      border: 1px solid $black;
    }
  }
  .onboarding-form {
    width: 85%;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    justify-items: flex-start;
    align-items: flex-start;
    align-self: flex-start;
   
  }
  .operator-form {
    .captcha-class {
      font-size: 12px;
      color: #fff;
      padding: 0px 1rem;
      margin-bottom: 1.3rem;
    }
    @media only screen and (max-width: 576px) {
      width: 100%;
    }
    
  }

  .contact-information-container {
    text-align: center;
    margin: 50px auto;
    color: #618fd3;
    font-family: "Graphik-Medium";
    font-size: 22px;
  }
}
