@import "../../../components/variables.scss";
@import "../../../components/mixins.scss";

.bid-operator-form {
  @include display-column;
  width: 100%;
  align-items: center;
  @media only screen and (max-width: 576px) {
    align-items: unset;
  }

  .bid-operator-fields {
    .input-error-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: auto;
      p {
        padding: 0;
        height: 0.4rem;
      }
    }

    .icon-text {
      display: flex;
      align-items: center;
    }
    .marginSpace {
      margin-bottom: 0.4rem;
    }
    .inputDiv {
      width: 278px;
      height: auto;
      text-align: justify;
      border-radius: 6px;
      padding: 0.8rem;
      box-sizing: border-box;
      background: transparent;
      background-color: rgba(255, 255, 255, 0.08);
      border: none;
      border: solid 0.8px rgba(255, 255, 255, 0.19);
      color:$white;
      font-size: 10px;
      margin-bottom: 0.9rem;
      line-height: 1.4;
      letter-spacing: normal;
      @media only screen and (max-width: 576px) {
        width: 100%;
      }

      .form-checkbox {
        @include display-column;
        width: 100%;
        background: transparent;
        font-family: $mediumFont;
        font-size: 10px;

        .title {
          margin-bottom: 1rem;
          font-size: 11px;
        }

        .form-checkboxs {
          width: 90%;

          fieldset.group {
            margin: 0;
            padding: 0;
            margin-bottom: 1.25em;
            padding: 0.125em;
          }

          fieldset.group legend {
            margin: 0;
            padding: 0;
            font-weight: bold;
            margin-left: 20px;
            font-size: 100%;
            color: black;
          }

          ul.checkbox {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
              display: inline-block;
              width: 40%;
              margin-right: 1.2rem;
              .li-flex {
                width:9rem;
                @include align-center;
                justify-content: left;

                input {
                  align-self: baseline;
                }
                label {
                  margin-left: 0.4rem;
                  @media only screen and (max-width: 576px) {
                    margin-left: 1px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .input-box {
      width: 100%;
      height: 3rem;
      text-align: justify;
      border-radius: 6px;
      padding: 0.8rem;
      box-sizing: border-box;
      background: transparent;
      background-color: rgba(255, 255, 255, 0.08);
      border: none;
      border: solid 0.8px rgba(255, 255, 255, 0.19);
      font-size: 11px;
      color: $white;
      margin-bottom: 0.5rem;
      height: 2.5rem;
      font-family: $mediumFont;
      display: flex;
      align-items: baseline;

      @media only screen and (max-width: 576px) {
        width: 100%;
        @include align-center;
      }
      p {
        margin: 0px;
      }
      
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      outline: 0;
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.08);
      background-image: none;
      flex: 1;
      color: #fff;
      cursor: pointer;
      background-color: transparent;
    }
    & option {
      color: $black;
    }
     .select-hover {
        &:hover {          
          border: 0.3px solid rgb(57, 165, 233);
        }
      }   
    .select-width {
      width: 100%;
      position: relative;
      @media only screen and (max-width: 576px) {
        width: 100%;
      }  
      /* .select-hover {
        & :hover {
          border: 0.3px solid rgb(57, 165, 233);
        }
      }  */    
    }
  
  }
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.87);
    -webkit-transform: scale(0.9);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    @media only screen and (max-width: 576px) {
      transform: scale(0.1);
      -webkit-transform: scale(0.97);
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
    }
  }

  .arrow {
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    float: right;
  }

  .drop-arrow {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    color: #fff;
  }
  .downloadIcons {
    width: 23px;
    height: 100%;
    opacity: 0.8;
    font-size: 1em;
    margin-right: 0.3rem;
    @include align-center;
  }
  .attachIcons {
    width: 23px;
    height: 17px;
    opacity: 0.8;
    font-size: 1em;
    margin-right: 0.3rem;
    @include align-center;
  }
}
