@import "../../../components/variables.scss";
@import "../../../components/mixins.scss";

.bid-accordian-container {
  @include row-space-between;
  .bid-accordian-desc {
    color: $blueColor;
    font-family: $mediumFont;
    font-size: 22px;
    @media only screen and (max-width: 576px) {
      margin-bottom: 3rem;
    }
  }
  .FAQ-container {
    width: 52%;
    @include display-row;
    .faqs {
      width: 100%;
      max-width: 768px;
      margin: 0 auto;   
      @include display-column;
      .expand-icons {
        width: 5%;
        position: relative;
        @media only screen and (max-width: 576px) {
          width: 10%;
        }
      }
    }
    @media only screen and (max-width: 576px) {
      width: 100%;
      word-wrap: normal;
      justify-content: center;
      align-items: center;
    }
  }
  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }
}
