.bid-my-ride-page {
  width: 100%;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 576px) {
    display: block;
  }

  .map-section-wrap {
    margin-right: 15px;
    margin-left: 15px;
    width: 350px;

    @media only screen and (max-width: 576px) {
      width: 90%;
      margin: auto;
    }

    .map-section-container {
      background-color: rgba(0, 0, 0, 0.75);
      border-radius: 6.9px;
      padding: 15px;
      .map-section-data {
        .map-section-each-row {
          display: flex;
          color: #fff;
          font-family: "SFCompactDisplayRegular";
          span {
            font-size: 16px;
          }
          .map-section-label {
            width: 40%;
          }
          .map-section-description {
            width: 60%;
            padding-left: 5px;
          }
        }
      }
      &-title {
        color: #fff;
        font-family: "SFCompactDisplayRegular";
      }
    }
    

  }

  .bid-my-form-container {
    width: 425px;

    @media only screen and (max-width: 576px) {
      width: 90%;
      margin: 20px auto;
    }
}
}
