.primary-text {
    font-size: 1.375rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    color: #618fd3;
    @media only screen and (max-width: 576px) {
        font-size: 1rem;
    }
}
.text-medium {
    font-family: "Graphik-Medium";
    font-weight: 500;
}