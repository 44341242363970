@import "../../../../components/variables.scss";
@import "../../../../components/mixins.scss";

.faq {
  .plus-minus-toggle {
    cursor: pointer;
    height: 10px;
    position: relative;
    width: 10px;
    &:before,
    &:after {
      background: $blueColor;
      content: "";
      height: 2px;
      left: 0;
      position: absolute;
      top: 0;
      width: 10px;
      transition: transform 100ms ease;
    }
    &:after {
      transform-origin: center;
    }
    &.collapsed {
      &:after {
        transform: rotate(90deg);
      }
      &:before {
        transform: rotate(180deg);
      }
    }
  }
  .hr-line {
    width: 100%;
    float: right;
    border: 0.5px solid gray;
    opacity: 0.1;
    transition: transform 500ms ease;
  }

  .faq-question {
    width: 100%;
    position: relative;
    font-size: 13px;
    transition: all 0.4s ease;
    color: #373737;
    font-family: $mediumFont;
  }
  .faq-answer {
    width: 85%;
    opacity: 0;
    max-height: 0;
    text-align: left;
    transition: transform 500ms ease;
    @extend .para-faq;
    @media only screen and (max-width: 576px) {
      width: 90%;
    }

    .para-faq {
      @include font-normal;
      font-size: 12px;
    }
  }
}
.faq.open {
  .faq-question {
    margin-bottom: 0.6rem;
  }

  .faq-answer {
    max-height: 1000px;
    opacity: 1;
    transition: transform 500ms ease;
    height: auto;
    display: block;
  }
}
