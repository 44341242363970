.bid-my-ride-search-form {
  -webkit-backdrop-filter: blur(10.3px);
  backdrop-filter: blur(10.3px);
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 6.9px;
  padding-top: 6px;
  width: 35%;

  .form-title {
    font-size: 24px;
    line-height: 1.37;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
    font-family: "SFCompactDisplayRegular";
  }

  .input-field {
    padding: 5px 0 5px 15px;
    border-radius: 6.9px;
    border: 2.3px solid hsla(0, 0%, 100%, 0.4);
    margin: 6px;
    height: 57px;

    label {
      display: block;
      font-size: 15px;
      color: #fff;
      font-family: "SFCompactDisplayMedium";
      margin: 0;
      text-transform: uppercase;
    }

    input {
      outline: none;
      border: none;
      display: block;
      line-height: 1.2em;
      font-size: 16px;
      width: 100%;
      color: #fff;
      background-color: transparent;
      font-family: "SFCompactDisplayMedium";
      padding: 0;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 0.5;
      color: #fff;
    }
  }

  .input-field-checkbox {
    margin: 6px;

    .agree_checkbox_label {
      color: #fff;
    }

    .agree_checkbox_text {
      margin-right: 5px;
    }
  }

  .textareaType {
    height: auto;
  }

  .error-field {
    text-align: left;
    margin-bottom: 5px;
    line-height: 18px;

    p {
      margin-left: 10px;
      font-family: "SFCompactDisplayMedium";
      color: #ff6181 !important;
      letter-spacing: 1px;
      text-shadow: none !important;
      font-size: 17px;
    }
  }

  textarea {
    outline: none;
    border: none;
    display: block;
    line-height: 1.2em;
    font-size: 17px;
    width: 100%;
    color: #fff;
    background-color: transparent;
    font-family: "SFCompactDisplayMedium";
  }

  select {
    background-color: transparent;
    outline: none;
    border: none;
    line-height: 1.2em;
    font-size: 17px;
    width: 100%;
    color: #fff;
    font-family: "SFCompactDisplayMedium";

    optgroup,
    option {
      color: #000;
    }
  }

  select:invalid {
    opacity: 0.5;
    color: #fff;
  }

  .submit-container {
    text-align: center;
    opacity: 0.8;
    border-radius: 6.9px;
  }

  input[type="submit"] {
    height: 40px;
    width: 70%;
    color: #fff;
    line-height: 1.72;
    letter-spacing: normal;
    margin: 10px auto;
    font-size: 17px;
    font-weight: 600;
    font-family: "SFCompactDisplayBold";
    cursor: pointer;
    border-radius: 17px;
    line-height: 1.72;
    padding: 5px 0;
    background-image: linear-gradient(108deg, #6f88aa 21%, #394c6c 74%);
  }

  .input-radio-field {
    font-size: 18px;
    font-family: "SFCompactDisplayMedium";
    color: white;
    height: 65px;

    label {
      display: inline;
    }

    input {
      display: inline;
      width: auto;
      margin-right: 5px;
    }
  }

  .google-recaptcha {
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 1rem;
  }

  .date-time-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .date-container {
      width: 50%;
    }

    .time-container {
      display: flex;
      flex-wrap: wrap;
      width: 45%;
  
      .hour-container {
        width: 42%;
        margin-right: 10px;
      }
    }
  }

  .form-control:focus {
    box-shadow: none;
  }
}
