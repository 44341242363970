.expired-notice {
    text-align: center;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
  }
  
  .expired-notice > span {
    font-size: 2.5rem;
    font-weight: bold;
    color: red;
  }
  
  .expired-notice > p {
    font-size: 1.5rem;
  }
  
  .show-counter {
    margin-bottom: .5rem;
    background-color: #FF726F;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);

    .countdown-text-container {
      margin: 0 20px;
      padding-bottom: 10px;

      .countdown-header-text,
      .countdown-subHeader-text {
        text-align: center;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 500;
        font-family: "SFCompactDisplayRegular";
        line-height: 1.4;
        color: #fff;
      }
    }
  }
  
  .show-counter .countdown-link {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "SFCompactDisplayLight";
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    text-decoration: none;
    color: #fff;
  }
  
  .show-counter .countdown {
    line-height: 1.25rem;
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .show-counter .countdown.danger {
    color: #ff0000;
  }
  
  .show-counter .countdown > p {
    margin: 0;
  }
  
  .show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 14px;
  }
  