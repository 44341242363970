@import "./variables.scss";
@mixin align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin display-column {
  display: flex;
  flex-direction: column;
}

@mixin display-row {
  display: flex;
  flex-direction: row;
}
@mixin row-space-between {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

@mixin inputBox(
  $width: 98%,
  $height: 2.3rem,
  $textAlign: justify,
  $boxSize: border-box,
  $background: transparent,
  $bgColor: #79a0da,
  $border: none,
  $webkitShadow: 0 0px 0px 0px rgba(0, 0, 0, 0.3),
  $color: #ffff,
  $mdMarginBottom: 0.7rem
) {
  input[type="text"] {
    width: $width;
    height: $height;
    text-align: $textAlign;
    box-sizing: $boxSize;
    border-radius: 6px;
    background: $background;
    background-color: $bgColor;
    border: $border;
    -webkit-box-shadow: $webkitShadow;
    box-shadow: $webkitShadow;
    color: $color;
  }
  & ::placeholder {
    color: #ffff;
  }
}
@mixin font-normal {
  font-size: 11px;
  line-height: 1.55;
  color: #373737;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-family: $regularFont;
}
