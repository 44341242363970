@import "../../../../components/variables.scss";
@import "../../../../components/mixins.scss";

.input_default {
    input[type="text"] {
        width: 100%;
        height: 35px;
        text-align: justify;
        border-radius: 6px;
        padding: 0.8rem;
        box-sizing: border-box;
        background: transparent;
        border: solid 0.9px rgba(255, 255, 255, 0.19);
        background-color: rgba(255, 255, 255, 0.08);
        font-size: 11px;
        font-family: $mediumFont;
        color: $white;
        box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.3);
        transition: 0.3s;
        &:focus {
            outline: none;
        }
        &:hover {
            border: 0.3px solid rgb(57, 165, 233);
        }
        &::placeholder {
            font-family: $mediumFont;
            color: #373737;
            color: $white;
            font-size: 11px;
            font-weight: 500;
            font-style: normal;
        }
    }
}

.marginSpace {
    margin-bottom: 0.4rem;
}
