@import "../../../components/variables.scss";
@import "../../../components/mixins.scss";

.footer-component-new {
  background-color: $blueColor;
  border: 1px solid $blueColor;
      background-image: linear-gradient(144deg, #547db8 27%, #445c7e 97%);

  align-items: center;
  min-height: 300px;
  @include align-center;
  

  @media only screen and (max-width: 576px) {
    bottom: 165px;
  }
  .footer-content {
    width: 60%;
    margin-top: 4rem;
    color: $white;
    @include align-center;
    font-family: $mediumFont;
    @include display-column;
    .footer-header {
      width: 100%;
      font-family: $mediumFont;
      font-size: 36px;
      color: #fff;
      text-align: center;
      margin-bottom: 2rem;

      @media only screen and (max-width: 576px) {
        font-size: 32px;
        width: 350px;
        text-align: center;
      }
    }
    .bid-footer-signup {
      width: 100%;
      min-width: 400px;
      display: flex;
      flex-direction: column;

      button {
        margin-top: 0.5rem;
        width: 100%;
        margin-bottom: 3rem;
      }

      @media only screen and (max-width: 576px) {
        width: 100%;
        min-width: 350px;
      }

      .bid-footer-fields {
        @include row-space-between;
        justify-content: space-around;
        p {
          height: 0.3rem;
        }

        @media only screen and (max-width: 576px) {
          width: 100%;
          flex-direction: column;
          margin-bottom: 0;
        }
        @include inputBox;

        input[type="text"] {
          background: transparent;
          background-color: rgba(255, 255, 255, 0.08);
          border: none;
          border: solid 0.8px rgba(255, 255, 255, 0.19);
        }
        input[type="text"]:hover {
          border: 0.3px solid rgb(57, 165, 233);
        }

        
       
        .footer-input-container {
          width: 80%;
          .error-display {
            display: block;
            margin-top: 0.1rem;
          }

          @media only screen and (max-width: 576px) {
            width: 100%;
            margin-bottom: 0.6rem;
          }
        }
      }
    }
  }
}
