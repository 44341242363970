.bid-history {
  font-family: "Graphik-Regular";
  min-width: 187px;
  width: 20%;
  display: flex;
  flex-direction: column;
  background-color: #f8f5f5;
  border-radius: 10px;
  justify-items: center;
  align-items: center;
  padding: 1.25rem 0.75rem;
  opacity: 1;
  margin-right: 1rem;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #373737;
  @media only screen and (max-width: 576px) {
    margin-bottom: 0.5rem;
  }
  .bid-box {
    width: 92%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    .bid-number {
      text-align: center;
      font-size: 60px;
      color: #618fd3;
      font-family: "Graphik-Light";
      object-fit: contain;
      width: 65%;
      height: 45%;
      margin-bottom: 1.6rem;

      @media only screen and (min-width: 1500px) {
        width: 150px;
        height: 80px;
      }
      @media only screen and (min-width: 2000px) {
        width: 150px;
        height: 80px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .bid-text {
      width: 100%;
      color: #373737;
      font-size: 17px;
      align-self: center;
      text-align: center;
      font-family: "Graphik-Regular";
    }
  }
}
