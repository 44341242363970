* {
  margin: 0;
}


@font-face {
  font-family: "SFCompactDisplayBlack";
  src: local("SFCompactDisplayBlack"), url("./fonts/SFCompactDisplay/SF-Compact-Display-Black.ttf") format("truetype");
}

@font-face {
  font-family: "SFCompactDisplayBold";
  src: local("SFCompactDisplayBold"), url("./fonts/SFCompactDisplay/SF-Compact-Display-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SFCompactDisplayHeavy";
  src: local("SFCompactDisplayHeavy"), url("./fonts/SFCompactDisplay/SF-Compact-Display-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "SFCompactDisplayLight";
  src: local("SFCompactDisplayLight"), url("./fonts/SFCompactDisplay/SF-Compact-Display-Light.ttf") format("truetype");
}

@font-face {
  font-family: "SFCompactDisplayMedium";
  src: local("SFCompactDisplayMedium"), url("./fonts/SFCompactDisplay/SF-Compact-Display-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SFCompactDisplayRegular";
  src: local("SFCompactDisplayRegular"), url("./fonts/SFCompactDisplay/SF-Compact-Display-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SFCompactDisplayThin";
  src: local("SFCompactDisplayThin"), url("./fonts/SFCompactDisplay/SF-Compact-Display-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "SFCompactDisplayUltraLight";
  src: local("SFCompactDisplayUltraLight"), url("./fonts/SFCompactDisplay/SF-Compact-Display-Ultralight.ttf") format("truetype");
}

