.footer-component {
  width: 60%;
  margin: 50px auto;
  .horizontal-line {
    width: 100%;
    height: 1px;
    opacity: 0.15;
    margin: 20px auto;
    border: solid 0.8px #000;
  }

  .footer-text {
    text-align: center;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    text-align: center;
    color: #212121;
    font-family: "SFCompactDisplayRegular";
  }
}
