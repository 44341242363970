@mixin ErrorMsg($color: #faea4f, $padding: 0.6rem 0px 0px 0px, $fontSize: 11px, $family: "Graphik-Medium") {
    color: $color;
    padding: $padding;
    font-family: $family;
    font-size: $fontSize;
}
.error-text {
    @include ErrorMsg;
}
.error-red {
    @extend .error-text;
    color: red;
    padding: 0.3rem 0px 0px 0px;
}
